/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 14, 2020 */



@font-face {
    font-family: 'fleurondingbats';
    src: url('fleurondingbats-webfont.woff2') format('woff2'),
         url('fleurondingbats-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fleuronmixed';
    src: url('fleuronmixed-webfont.woff2') format('woff2'),
         url('fleuronmixed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'fleuronregular';
    src: url('fleuronregular-webfont.woff2') format('woff2'),
         url('fleuronregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}